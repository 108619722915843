import React, { ReactElement } from "react";
import {
  Breadcrumbs,
  BannerHeader,
  FixedSharpImageFile,
  BreadcrumbItems,
  Meta,
} from "@ymcansw-camping/common";
import clsx from "clsx";
import { graphql } from "gatsby";
import Layout, { LayoutProps } from "./layout";

type GeneralPageLayoutProps = LayoutProps & {
  readonly bannerHeaderImage: FixedSharpImageFile;
  readonly meta: Meta;
  readonly pageContentClassName?: string;
  readonly breadcrumbs?: BreadcrumbItems;
};

export default function GeneralPageLayout({
  meta,
  bannerHeaderImage,
  children,
  pageContentClassName,
  breadcrumbs,
}: GeneralPageLayoutProps): ReactElement {
  const useBreadcrumbs = breadcrumbs ?? [meta.title];
  return (
    <Layout meta={meta}>
      <BannerHeader title={meta.title} image={bannerHeaderImage} />
      <div
        className={clsx(["page-content", "no-sidebar", pageContentClassName])}
      >
        <div className="container">
          <div className="page-content-header">
            <div className="row">
              <div className="col-xs-12 col-md-7 col-lg-8">
                <Breadcrumbs items={useBreadcrumbs} />
              </div>
            </div>
          </div>
          <div className="content">{children}</div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  fragment GeneralPageLayout_bannerHeaderImage on File {
    childImageSharp {
      fixed(height: 200) {
        src
      }
    }
  }
`;
