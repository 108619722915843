import React from "react";
import { graphql } from "gatsby";
import {
  FixedSharpImageFile,
  GatsbyMarkdownFile,
  Meta,
} from "@ymcansw-camping/common";
import GeneralPageLayout from "../components/general-page-layout";

type GeneralPageProps = {
  readonly data: {
    readonly file: GatsbyMarkdownFile<{
      readonly meta: Meta;
      readonly image: FixedSharpImageFile;
    }>;
  };
};

function GeneralPage({ data }: GeneralPageProps) {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: { meta, image },
      },
    },
  } = data;
  return (
    <GeneralPageLayout meta={meta} bannerHeaderImage={image}>
      {/* eslint-disable-next-line react/no-danger */}
      <div
        className="content-block"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </GeneralPageLayout>
  );
}

export const query = graphql`
  query GeneralPageQuery($id: String!) {
    file(id: { eq: $id }) {
      childMarkdownRemark {
        html
        frontmatter {
          slug
          meta {
            title
            description
          }
          image {
            ...GeneralPageLayout_bannerHeaderImage
          }
        }
      }
    }
  }
`;

export default GeneralPage;
